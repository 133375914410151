.currentMarkerControlDivCLASS:hover .currentMarkerEditIcon {
  opacity: 1;
}

.currentMarkerEditIcon {
  opacity: 0.4;
}

.cursor-default {
  cursor: default;
}

.blockie {
  border-radius: 50%;
}
